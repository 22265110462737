import { IMenu } from 'src/interface/layout';
// import { v4 as uuidv4 } from 'uuid';
// import sanitizeHtml from 'sanitize-html';
import _ from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { APP_IMG } from 'src/constants';
// import { NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID, NEXT_PUBLIC_TENCENT_CLOUD_M3U8_URL, NEXT_PUBLIC_TENCENT_CLOUD_SRC_URL } from 'src/constants/index';
// import { APP_IMG, NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID, NEXT_PUBLIC_TENCENT_CLOUD_M3U8_URL, NEXT_PUBLIC_TENCENT_CLOUD_SRC_URL } from 'src/constants';
import { NAV_DATA } from 'src/redux/navSlice';
import { GET } from 'src/services';
import { TInitPropsMember } from 'src/services/member-auth/member.auth.service';
import {
  DATALAYER_TYPE
  // sendDataLayerGlobal
} from 'src/utils/datalayer/send-datalayer';
import { timestamp } from 'src/utils/helper';
// import Cookies from 'js-cookie';
// import styled from '@emotion/styled';
// import { APP_IMG } from 'src/constants';
// import { NATION_TV_PUBLIC_PWA } from 'src/constants';
// import { SHOW_COMMEMORATE } from 'src/constants';

const Header = dynamic(import('src/components/layout/header'), { ssr: true });
const Footer = dynamic(import('src/components/layout/footer'), { ssr: true });
type TLayout = TInitPropsMember & {
  children: ReactNode;
  sectionCover?: any;
};

declare global {
  interface Window {
    TcPlayer: any;
  }
}
const Layout: NextPage<TLayout> = ({ children, sessionSevId, sectionCover }) => {
  const [navData, setNavData] = useState<IMenu[]>([]);
  const router: NextRouter = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const res: IMenu[] = await GET('/api/v1.0/navigations/menu-nav?limit=5', false, false);
        !_.isEmpty(res) && setNavData(res);
        const find = res?.filter(i => i.nameEng === router?.query?.cid);
        if (!_.isEmpty(find)) {
          dispatch(NAV_DATA(find));
        }
      } catch (err: any) {
        console.error(`${timestamp()} ==========> MENU_ERROR : `, err.message);
      }
    })();
  }, []);

  // useEffect(() => {
  //   const isMobile = window.matchMedia("(max-width: 768px)").matches;

  //   const script = document.createElement('script');

  //   script.src = `${NEXT_PUBLIC_TENCENT_CLOUD_SRC_URL || ""}`;
  //   script.charset = 'utf-8';
  //   script.async = true;
  //   script.onload = () => {
  //     new window.TcPlayer(`${NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID || ""}`, {
  //       "m3u8": `${NEXT_PUBLIC_TENCENT_CLOUD_M3U8_URL || ""}`,
  //       "preload": "true",
  //       "autoplay": !isMobile,
  //       "poster": `${APP_IMG}/images/default.jpg`,
  //       "width": '100%',
  //       "height": '100%',
  //       "muted": !isMobile, // เปิดเสียงหากเป็น Mobile
  //       "volume": isMobile ? 1 : 0 // 2 ถ้าเป็น Mobile, 0 ถ้าเป็น Desktop
  //     });
  //     const videoElement = document.getElementById(NEXT_PUBLIC_TENCENT_CLOUD_DIV_ID) as HTMLVideoElement;
  //     if (videoElement) {
  //       const labelDom = videoElement.querySelector('.vcp-player .vcp-controls-panel .vcp-timelabel');
  //       // const playDom = videoElement.querySelector('.vcp-player .vcp-controls-panel .vcp-playtoggle');
  //       if (labelDom) {
  //         labelDom.remove();
  //       }
  //     }
  //   };
  //   document.head.appendChild(script);

  //   return () => {
  //     // Cleanup script when component unmounts
  //     document.head.removeChild(script);
  //   };
  // }, [router.isReady]);

  return (
    <>

      <Header data={navData} sessionSevId={sessionSevId} sectionCover={sectionCover} eventDataLayer={{
        type: DATALAYER_TYPE.TRACK,
        router: router?.pathname
      }} />
      {children}
      <Footer data={navData} />
    </>
  );
};
export default Layout;
